@import "../../App.scss";

.light-mode-card {
  background: #f8f8f8;
}

.dark-mode-card {
  background: #222;
}

.review-card {
  max-width: 400px;
  width: 400px;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &__profile-pic {
    display: block;
    margin: auto;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 12px;
  }
  &__name {
    font-family: $font-family-1;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.06em;
    text-align: center;
  }
  &__designation {
    font-family: $font-family-1;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.06em;
    margin-bottom: 30px;
    text-align: center;
  }
  &__review {
    margin-top: 24px;
    font-family: $font-family-1;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.06em;
    max-width: 270px;
  }
}
