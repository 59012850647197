/** Fonts */
$metropolis: "Metropolis";
$work-sans: "Work Sans";

$font-family-1: $metropolis, sans-serif;
$font-family-2: $metropolis, $work-sans, serif;
// Fonts end

// products color schemes 

$meri-bachat-bgc: #FFF8C8;
$meri-bachat-text: #525FBF;

$menova-bgc: #000000;
$menova-text: #FF914C;

$kidscur-bgc: #957AFF;
$kidscur-text: #fff;

$altilium-bgc: #FF9AA2;
$altilium-text: #fff;

$ombhu-bgc: #A3B770;
$ombhu-text: #000;


// products color schemes end 

* {
  box-sizing: border-box;
}

body {
  background-color: whitesmoke;
}

a,
a:hover,
a :visited {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;
  color: #000000;
}

.typewriter-char {
  opacity: 0;
}

html,
body {
  // cursor: none;
  overflow-x: hidden;
  overflow-y: overlay;
}

// .cursor {
//   width: 30px;
//   height: 30px;
//   border-radius: 100%;
//   position: fixed;
//   border: 1px solid #373737;
//   top: 50%;
//   pointer-events: none;
//   left: 50%;
// }

.light-mode__color0 {
  color: #000000;
}

.light-mode__color1 {
  color: #373737;
}

.dark-mode__color1 {
  color: #ffffff !important;
}

.light-mode__color2 {
  color: #676767;
}

.dark-mode__color2 {
  color: rgba(255, 255, 255, 0.7) !important;
}
