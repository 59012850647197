@import "../../App.scss";

.build-better__underline {
  font-family: $font-family-1;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
}
.st__t {
  transform-origin: 21.3% 39.79%;
}
.index-section {
  padding-top: 89px;
  height: calc(100vh + 89px);
  padding: 0 200px;
}

.index__first-section {
  display: flex;
  padding-top: 89px;
  align-items: center;
  // justify-content: center;
  flex-flow: row wrap;
  height: calc(100vh);
}
.index__first-section .st-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.heading {
  font-family: $font-family-1;
  font-style: normal;
  font-weight: 900;
  font-size: 132px;
  line-height: 100%;
}
.heading-2 {
  font-size: 44px;
}
.heading-gradient {
  background: linear-gradient(90deg, #60dda8 0%, #3be0b9 50%, #b4de5a 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  // text-fill-color: transparent;
}
.index-text-body {
  font-family: $font-family-2;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  max-width: 380px;
}

.explore {
  margin-bottom: 25px;
  width: 100%;
  &__text,
  &__arrow {
    font-family: "Century Gothic Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    text-align: center;
    color: #676767;
  }
}

.index__second-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.second-section {
  &__mark {
    position: relative;
    left: -50px;
  }
  &__text {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    &-title {
      margin-bottom: 12px;
      &__first-line {
        margin-bottom: 25px;
        svg {
          margin-right: 10px;
        }
      }
    }
    &-body {
      font-family: $font-family-2;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      max-width: 400px;
      color: #676767;
    }
  }
}

.third-section {
  display: flex;
  // width: 100%;
  flex-flow: row;
  // justify-content: space-between;
  align-items: center;
  &__left {
    position: relative;
    &__splash {
      position: absolute;
      top: 35px;
      left: -175px;
    }
    &__laptop {
      position: relative;
      left: -350px;
    }
  }
  &__text {
    left: -350px;
    position: relative;
    &__title {
      display: flex;
      flex-flow: column wrap;
    }
    &__body {
      font-family: $font-family-2;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      max-width: 400px;
      color: #676767;
      margin-top: 32px;
    }
  }
}

.fourth-section {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 150px;
  // &__icon {
  //   width: 50%;
  //   display: flex;
  //   flex-flow: column-reverse;
  //   & svg {
  //     padding: 0px 0;
  //   }
  // }
  &__middle {
    flex: 1 0 auto;
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    &__title {
      margin-bottom: 25px;
    }
    &__body {
      color: #c9c9c9;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      max-width: 530px;
    }
    &__explore {
      margin-top: 10px;
      color: white;
    }
  }

  &__right {
    display: flex;
    flex-direction: row;
    gap: 3px;
    flex: 1 0 auto;
    width: 50%;
  }
  &__left {
    display: flex;
    flex-flow: row wrap;
    width: 40%;
    gap: 60px;
  }
}

#services-wrench {
  transform-origin: 162px 126px;
}

.service-card:nth-child(even) {
  transform: translate(0, 40px);
}

@media screen and (max-width: 1750px) {
  .fourth-section {
    &__left {
      width: 50%;
    }
  }
}

@media screen and (max-width: 1588px) {
  .fourth-section {
    &__icon {
      svg {
        padding: 50px 0 0 0;
      }
      height: fit-content;
    }
    &__right {
      flex-direction: column;
    }
  }
}
@media screen and (max-width: 1460px) {
  .heading {
    font-size: 100px;
  }

  .heading-2 {
    font-size: 32px;
  }

  .st-right {
    width: 100px;
  }

  .fourth-section {
    &__left {
      gap: 30px;
    }
  }
  .service-card {
    width: 200px;
    height: 200px;
  }
}

@media screen and (max-width: 1380px) {
  .third-section {
    &__left {
      &__laptop, &__splash {
        left: -450px;
      }
    }
    &__text {
      left: -450px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .index-section{
    padding: 0 100px;
  }
}

@media screen and (max-width: 1140px) {
  .third-section {
    &__left {
      &__laptop, &__splash {
        left: -650px;
      }
    }
    &__text {
      left: -650px;
    }
  }
}

@media screen and (max-width: 1060px) {
  .build-better__underline {
    font-size: 24px;
  }
  .second-section__text-body {
    font-size: 20px;
  }
  .third-section__text__body {
    font-size: 20px;
  }
  .fourth-section__middle__body {
    font-size: 20px;
  }
  .fourth-section{
    padding: 100px 100px;
    display: block;
    height: fit-content;
  }
  .fourth-section__right {
    width: fit-content;
  }
  .fourth-section__left {
    margin-top: 50px;
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .index-section {
    padding: 40px;
  }
  .build-better-container {
    margin: auto;
  }
  .build-better__underline {
    margin-top: 0;
  }
  .explore {
    display: none;
  }
}

@media screen and (max-width: 845px) {
  .heading {
    font-size: 60px;
  }
  .heading-2 {
    font-size: 32px;
  }
}

@media screen and (max-width: 750px) {

  .cursor {
    display: none;
  }

  #st {
    transform: scale(0.5);
  }

  .heading {
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 900;
    font-size: 72px;
  }

  .heading-2 {
    font-size: 32px;
    line-height: 36px;
  }

  .build-better-container {
    margin: 0;
  }

  .build-better__underline {
    margin-top: 0;
    font-size: 24px;
    line-height: 28px;
  }

  .index__first-section {
    align-items: unset;
    justify-content: unset;
    .build-better-container {
      padding: 0 20px;
      justify-content: center;
      // align-items: center; // fixed strange padding on vw: 450px +
    }
    .st-right {
      padding-left: 10px;
    }
  }

  .second-section {
    flex-direction: column;
    margin: 75px 0;
    &__text {
      padding: 0 20px;
    }
  }

  .third-section {
    &__text {
      text-align: left;
      padding: 0 20px;
      justify-content: unset;
      align-items: center;
      text-align: center;
      left: unset;
      width: 100%;
      &__body {
        margin: 20px auto;
      }
    }
    &__left {
      display: none;
    }
  }

  .fourth-section {
    &__icon {
      // display: none;
      height: auto;
    }
    &__right {
      display: flex;
      flex-flow: column;
      width: auto;
      padding: 10px;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    &__left {
      padding: 0 0;
    }
  }

}

@media screen and (max-width: 500px) {

  .index-section {
    margin-top: 60px;
    height: fit-content;
  }
  .cursor {
    display: none;
  }

  #st {
    transform: scale(1);
  }

  .second-section__mark {
    display: none;
  }
  .heading {
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 900;
    font-size: 72px;
  }

  .heading-2 {
    font-size: 32px;
    line-height: 36px;
  }

  .build-better__underline {
    font-size: 24px;
    line-height: 28px;
  }

  .index__first-section {
    align-items: unset;
    .build-better-container {
      padding: 0 20px;
      justify-content: unset;
      align-items: unset;
    }
    .st-right {
      padding-left: 10px;
    }
  }

  .second-section {
    flex-direction: column;
    margin: 75px 0;
    &__text {
      padding: 0 20px;
    }
    &__text-body {
      font-size: 20px;
      line-height: 24px;
      max-width: 400px;
    }
  }

  .third-section {
    &__text {
      text-align: left;
      padding: 0 20px;
    }
    &__text__body {
      font-size: 20px;
      line-height: 24px;
      max-width: 400px;
    }
  }

  .fourth-section {
    padding: 40px;
    position: relative;
    &__icon {
      display: none;
      height: auto;
      position: absolute;
      right: 20px;
      top: 35px;
      svg {
        padding: 0;
      }
    }
    &__middle {
      max-width: unset;
      &__body {
        font-size: 20px;
        line-height: 24px;
        max-width: 400px;
      }
    }
    &__right {
      display: flex;
      flex-flow: column;
      width: auto;
      padding: 10px;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    &__left {
      padding: 0 0;
    }
  }

  .service-card {
    width: 150px;
    height: 150px;
  }
  .fourth-section__left {
    gap: 10px;
  }
}

@media screen and (max-width: 380px) {
  .fourth-section {
    &__icon {
      display: none;
    }
  }
}

@media screen and (max-width: 400px) {
  .index-section {
    margin-top: 100px;
    padding: 20px 0;
  }
  .fourth-section {
    padding: 20px;
  }
}

@media screen and (max-width: 350px) {
  .index-section {
    padding: 10px;
  }
  .fourth-section {
    padding: 40px 10px;
  }
  .index__first-section .build-better-container {
    padding: 0;
  }
}

@media screen and (max-width: 330px) {
  .heading {
    font-size: 60px;
  }
  .heading-2 {
    font-size: 32px;
  }
  .service-card {
    width: 140px;
    height: 140px;
  }
}
// fix small double scroll on mobile

.body {
  overflow: hidden;
}
