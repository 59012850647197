@import "../../App.scss";

.peopleCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 44px;
  &__img {
    width: 349px;
    height: 463px;
    object-fit: cover;
  }
  &__name {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    font-family: $font-family-1;
    text-align: center;
  }
  &__position {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    font-family: $font-family-1;
    text-align: center;
  }
}

// @media screen and (max-width: 960px) {
//   .peopleCard {
//     flex-direction: row;
//     align-items: flex-start;
//     justify-content: unset;
//     gap: 20px;
//     width: 100%;
//     &__img {
//       width: 112px;
//       height: 149px;
//       border-radius: 9px;
//       object-fit: cover;
//     }
//     &__name {
//       font-size: 20px;
//       line-height: 24px;
//       font-family: $font-family-1;
//       text-align: start;
//     }
    
//     &__position {
//       font-weight: 400;
//       font-size: 12px;
//       line-height: 14px;
//       font-family: $font-family-1;
//       text-align: start;
//     }
//   }
// }
