@import "../../App.scss";

.navbar {
  display: flex;
  padding: 30px 200px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  align-items: center;

  &__hamburger {
    display: none;

    &__line1 {
      width: 20px;
      height: 3px;
      margin: 2.5px;
      transition: all 0.3s ease;
    }

    &__line2 {
      width: 14px;
      height: 3px;
      float: right;
      margin: 2.5px;
      transition: all 0.3s ease;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    z-index: 100;
    // width: 100%;
    // height: 100%;

    &__img {
      // width: 100%;
      height: 25px;
      object-fit: contain;
    }
  }

  &__links {
    flex: 1 0;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 44px;
    font-family: $font-family-1;
    padding: 10px 5px;
    font-style: normal;
    z-index: 1;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;

    &__item {
      position: relative;
    }

    &__item:hover:before {
      position: absolute;
      bottom: -5px;
      left: 0%;
      width: 40px;
      height: 15px;
      border-radius: 25px;
      z-index: -1;
      animation: grow 0.1s 0.1s ease-in-out forwards;
      content: "";
    }
  }
}

// .null {
//   display: none;
// }

.hamburger__open .navbar__hamburger__line1 {
  transform: rotate(-45deg) translate(-2.5px, 3px);
}
.hamburger__open .navbar__hamburger__line2 {
  width: 20px;
  transform: rotate(45deg) translate(-2.5px, -3px);
}

@keyframes grow {
  0% {
    width: 0;
  }

  100% {
    width: 40px;
    background: #65ffbe;
  }
}

@media screen and (max-width: 1350px) {
  .navbar {
    padding: 30px 90px;
  }
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media screen and (max-width: 1070px) {
  .navbar {
    align-items: center;
    justify-content: space-between;

    &__logo {
      position: relative;
    }

    &__hamburger {
      display: block;
    }

    &__links {
      box-sizing: border-box;
      position: absolute;
      left: 0;
      top: -100%;
      flex-direction: column;
      align-items: center;
      width: 100vw;
      z-index: -1;
      transition: all 0.3s ease;
      justify-content: flex-start;
      padding-top: 60px;
      &__open {
        top: 89px !important;
        z-index: 1000 !important;
        height: 100vh;
      }

      &__item {
        opacity: 0;
        transform: translateX(100%);
        transition: transform 0.5s ease-in;

        a {
          font-size: 48px;
          font-weight: 900;
        }
        &__animate1 {
          animation: navLinkFade 0.3s ease forwards;
          animation-delay: 0.2s;
        }
        &__animate2 {
          animation: navLinkFade 0.3s ease forwards;
          animation-delay: 0.4s;
        }
        &__animate3 {
          animation: navLinkFade 0.3s ease forwards;
          animation-delay: 0.6s;
        }
        &__animate4 {
          animation: navLinkFade 0.3s ease forwards;
          animation-delay: 0.8s;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .navbar {
    padding: 30px 24px
  }
}
