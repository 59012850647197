@import "../../App.scss";

.meri-bachat-bgc {
  background-color: $meri-bachat-bgc;
}
.menova-bgc {
  background-color: $menova-bgc;
}
.kidscur-bgc {
  background-color: $kidscur-bgc;
}
.altilium-bgc {
  background-color: $altilium-bgc;
}
.ombhu-bgc {
  background-color: $ombhu-bgc;
}

.meri-bachat-text {
  color: $meri-bachat-text;
}
.menova-text {
  color: $menova-text;
}
.kidscur-text {
  color: $kidscur-text;
}
.altilium-text {
  color: $altilium-text;
}
.ombhu-text {
  color: $ombhu-text;
}

.project-details {
  &__container {
    width: 100%;
    padding: 0 12vw;
    padding-top: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  &__title {
    font-weight: 700;
    font-size: 100px;
    font-family: $font-family-1;
  }
  &__desc {
    font-family: $font-family-1;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 0.06em;
  }
  &__logo-cont {
    width: 100%;
    margin-top: 55px;
    height: 735px;
    border-radius: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &__top-left {
      position: absolute;
      top: -55px;
      left: -145px;
    }
    &__bot-right {
      position: absolute;
      top: 571px;
      right: -65px;
    }
  }
  &__data {
    margin-top: 200px;
    padding: 7vw 12vw;
    &__product {
      font-family: $font-family-1;
      font-size: 24px;
      letter-spacing: 0.06em;
      font-weight: 700;
      margin-bottom: 75px;
    }
  }
}

.menova {
  &__top-left {
    position: absolute;
    top: -25px;
    left: -55px;
  }
  &__bot-right {
    position: absolute;
    bottom: -60px;
    right: -105px;
  }
  &__line {
    height: 4px;
    width: 163px;
    margin-top: 16px;
    background-color: $menova-text;
  }
  &-data-div1 {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    &__img-cont {
      position: relative;
      right: -12vw;
      img {
        position: relative;
        z-index: 1;
        max-width: 100%;
      }
      &__ball {
        position: absolute;
        z-index: 0;
        right: -260px;
        bottom: -100px;
      }
      &__ball > svg {
        width: 45vw;
        height: 45vw;
      }
    }
    &__text-cont {
      max-width: 639px;
      position: absolute;
      top: 0;
      left: 0;
      &__heading {
        font-family: $font-family-1;
        font-weight: 600;
        font-size: 80px;
      }
      &__desc {
        margin-top: 72px;
        font-family: $font-family-2;
        font-size: 24px;
        letter-spacing: 0.05em;
      }
    }
  }
  &-data-div2 {
    display: flex;
    align-items: center;
    &__img-cont {
      position: relative;
      img {
        position: relative;
        z-index: 10;
        max-width: 100%;
      }
      &__ball {
        position: absolute;
        z-index: 0;
        left: calc(-12vw - 150px);
        top: 30%;
      }
      &__ball > svg {
        width: 30vw;
        height: 30vw;
      }
    }
    &__text-cont {
      &__desc {
        max-width: 400px;
        font-family: $font-family-2;
        font-size: 24px;
        letter-spacing: 0.05em;
        margin-left: -80%;
      }
    }
  }
}

.mb-data {
  &-div1 {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    &__line {
      height: 4px;
      width: 163px;
      margin-top: 16px;
      background-color: $meri-bachat-text;
    }
    &__img-cont {
      position: relative;
      img {
        position: relative;
        z-index: 10;
        max-width: 100%;
      }
      &__ball {
        position: absolute;
        z-index: 0;
        right: -12vw;
        top: 34%;
      }
      &__ball > svg {
        width: 35vw;
        height: 35vw;
      }
    }
    &__text-cont {
      max-width: 578px;
      &__heading {
        font-family: $font-family-1;
        font-weight: 600;
        font-size: 80px;
      }
      &__desc {
        margin-top: 72px;
        font-family: $font-family-2;
        font-size: 24px;
        letter-spacing: 0.05em;
      }
    }
  }

  &-div2 {
    display: flex;
    align-items: center;
    margin-top: -35%;
    &__img-cont {
      position: relative;
      img {
        position: relative;
        z-index: 10;
        max-width: 100%;
      }
      &__ball {
        position: absolute;
        z-index: 0;
        left: -12vw;
        bottom: 10px;
      }
      &__ball > svg {
        width: 30vw;
        height: 30vw;
      }
    }
    &__text-cont {
      &__desc {
        max-width: 400px;
        font-family: $font-family-2;
        font-size: 24px;
        letter-spacing: 0.05em;
      }
    }
  }
}

.kidscur {
  &-content {
    padding-bottom: 200px;
  }
  &-ph1 {
    margin-right: -5vw;
  }
  &-ph2 {
    margin-left: -5vw;
  }
  &-final {
    margin-top: 200px;
    width: 100%;
    img {
      position: relative;
      z-index: 1;
      display: block;
      margin: auto;
      max-width: 100%;
    }
    &__circle1 {
      position: absolute;
      right: 7vw;
      margin-top: -150px;
      z-index: 0;
      svg {
        width: 45vw;
        height: 45vw;
      }
    }
    &__circle2 {
      position: absolute;
      z-index: 0;
      margin-top: -600px;
      svg {
        width: 40vw;
        height: 40vw;
      }
    }
  }
  &__line {
    height: 4px;
    width: 163px;
    margin-top: 16px;
    background-color: $kidscur-text;
  }
}

.ombhu {
  &__line {
    height: 4px;
    width: 163px;
    margin-top: 16px;
    background-color: $ombhu-text;
  }
}

.altilium {
  &__line {
    height: 4px;
    width: 163px;
    margin-top: 16px;
    background-color: $altilium-text;
  }
  &-data-div3 {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    &__img-cont {
      position: relative;
      right: -12vw;
      img {
        position: relative;
        z-index: 1;
        max-width: 100%;
      }
      &__ball {
        position: absolute;
        z-index: 0;
        right: -60px;
        top: -50px;
      }
      &__ball > svg {
        width: 38vw;
        height: 38vw;
      }
    }
    &__text-cont {
      max-width: 394px;
      position: absolute;
      left: 0;
      &__heading {
        font-family: $font-family-1;
        font-weight: 600;
        font-size: 80px;
      }
      &__desc {
        font-family: $font-family-2;
        font-size: 24px;
        letter-spacing: 0.05em;
      }
    }
  }
}

// coming soon styles

.coming-soon {
  &__container {
    width: 95%;
    margin: auto;
    max-width: 1440px;
    height: calc(100vh - 260px);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__text {
    font-family: $font-family-1;
    font-weight: 900;
    font-size: 12vh;
    letter-spacing: 0.06em;
    color: rgba(0, 0, 0, 0.08);
    transform: rotate(-90deg);
    position: absolute;
    right: -10vw;
  }
  &__data {
    max-width: 930px;
  }
  &__name {
    font-family: $font-family-1;
    font-weight: 700;
    font-size: 100px;
    margin-bottom: 16px;
  }
  &__desc {
    font-family: $font-family-1;
    font-size: 24px;
    letter-spacing: 0.06em;
    max-width: 668px;
  }
}

@media screen and (max-width: 1600px) {
  .menova {
    &-data-div1 {
      &__text-cont {
        max-width: 555px;
        &__heading {
          font-size: 70px;
        }
        &__desc {
          font-size: 22px;
        }
      }
    }
    &-data-div2 {
      &__text-cont {
        &__desc {
          font-size: 22px;
        }
      }
    }
  }
}

@media screen and (max-width: 1570px) {
  .mb-data-div2 {
    margin-top: -22%;
  }
  .kidscur-final__circle2 {
    margin-top: -550px;
    left: 4vw;
  }
}

@media screen and (max-width: 1400px) {
  .mb-data {
    &-div1 {
      &__text-cont {
        width: 100%;
        &__heading {
          font-size: 50px;
        }
        &__desc {
          margin-top: 52px;
          font-size: 18px;
        }
      }
    }
    &-div2 {
      &__text-cont {
        &__desc {
          font-size: 18px;
        }
      }
    }
  }
  .menova {
    &-data-div1 {
      &__img-cont {
        img {
          max-width: 90%;
          right: -8vw;
        }
        &__ball {
          right: -260px;
          bottom: 0px;
        }
        &__ball > svg {
          width: 40vw;
          height: 40vw;
        }
      }
      &__text-cont {
        max-width: 450px;
        &__heading {
          font-size: 55px;
        }
        &__desc {
          margin-top: 32px;
          font-size: 18px;
        }
      }
    }
    &-data-div2 {
      &__text-cont {
        &__desc {
          font-size: 18px;
        }
      }
    }
  }
  .kidscur-final__circle2 {
    margin-top: -440px;
    left: 4vw;
  }
  // coming soon styles

.coming-soon {
  &__text {
    right: -20vw;
  }
}
}

@media screen and (max-width: 960px) {
  .meri-bachat-body {
    background-color: $meri-bachat-bgc;
  }
  .menova-body {
    background-color: $menova-bgc;
  }
  .kidscur-body {
    background-color: $kidscur-bgc;
  }
  .altilium-body {
    background-color: $altilium-bgc;
  }
  .ombhu-body {
    background-color: $ombhu-bgc;
  }
  .project-details {
    &__container {
      width: 100%;
      height: fit-content;
      padding: 0px 5vw;
      padding-top: 160px ;
    }
    &__title {
      font-size: 60px;

    }
    &__desc {
      font-size: 24px;
    }
    &__logo-cont {
      display: none;
    }
    &__data {
      margin-top: 60px;
      padding: 1vw 5vw;
      padding-bottom: 100px;
      &__product {
        display: none;
      }
    }
  }
  .mb-data {
    &-div1 {
      display: block;
      &__img-cont {
        margin-right: -4vw;
        &__ball {
          position: absolute;
          z-index: 0;
          right: -15vw;
          top: unset;
          bottom: 40px;
        }
        &__ball > svg {
          width: 65vw;
          height: 65vw;
        }
      }
      &__text-cont {
        width: 100%;
        max-width: unset;
        &__heading {
          font-size: 28px;
        }
        &__desc {
          margin-top: 32px;
          font-size: 16px;
        }
      }
    }
    &-div2 {
      display: block;
      margin-top: 60px;
      &__img-cont {
        margin-left: -4vw;
        &__ball {
          position: absolute;
          z-index: 0;
          left: -15vw;
          bottom: 60px;
        }
        &__ball > svg {
          width: 65vw;
          height: 65vw;
        }
      }
      &__text-cont {
        &__desc {
          font-size: 16px;
          margin-top: 12px;
        }
      }
    }
  }
  .menova {
    &__mob-color {
      color: #fff !important;
    }
    &-data-div1 {
      position: relative;
      display: block;
      &__img-cont {
        position: relative;
        right: 0;
        img {
          max-width: 110%;
          margin-left: -40px;
        }
        &__ball {
          position: absolute;
          z-index: 0;
          right: -90px;
          bottom: -20px;
        }
        &__ball > svg {
          width: 55vw;
          height: 55vw;
        }
      }
      &__text-cont {
        max-width: unset;
        width: 100%;
        position: relative;
        &__heading {
          font-size: 28px;
        }
        &__desc {
          font-size: 16px;
        }
      }
    }
    &-data-div2 {
      display: block;
      margin-top: 30px;
      &__img-cont {
        position: relative;
        img {
          position: relative;
          z-index: 10;
          max-width: 100%;
        }
        &__ball {
          position: absolute;
          z-index: 0;
          left: calc(-5vw - 50px);
          top: 20%;
        }
        &__ball > svg {
          width: 45vw;
          height: 45vw;
        }
      }
      &__text-cont {
        &__desc {
          font-size: 16px;
          margin-left: 0;
        }
      }
    }
  }
  .kidscur {
    &-ph1 {
      margin-right: 0;
    }
    &-ph2 {
      margin-left: 0;
    }
    &-final__circle1 {
      display: none;
    }
    &-final__circle2 {
      display: none;
    }
  }
  .altilium-data {
    &-div3 {
      display: block;
      margin-top: 30px;
      &__img-cont {
        position: relative;
        right: unset;
        &__ball {
          position: absolute;
          z-index: 0;
          right: -15vw;
          top: unset;
          bottom: 0px;
        }
        &__ball > svg {
          width: 45vw;
          height: 45vw;
        }
      }
      &__text-cont {
        width: 100%;
        position: relative;
        &__desc {
          margin-top: 32px;
          font-size: 16px;
        }
      }
    }
  }
  .coming-soon {
    &__container {
      flex-direction: column-reverse;
    }
    &__text {
      transform: rotate(0deg);
      position: relative;
      right: unset;
      left: 0;
      font-size: 20vw;
      margin-bottom: 30px;
      width: 100%;
    }
    &__data {
      width: 100%;
      max-width: unset;
    }
    &__name {
      font-size: 50px;
    }
    &__desc {
      font-size: 16px;
    }
  }
}
