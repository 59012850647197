@import "../../App.scss";

.services-second-section__container {
  width: 100%;
  height: 100vh;
  background-color: #010101;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.services-second__link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fcfcfc;
  &__number {
    padding: 15px;
    font-family: $font-family-1;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
  }
  &__text {
    padding: 15px;
    border-bottom: 1px solid rgba(252, 252, 252, 0.45);
    width: 615px;
    max-width: calc(95vw - 60px);
    font-family: $font-family-2;
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 61px;
  }
}

.design-section {
  &__heading {
    font-family: $font-family-2;
    font-weight: 700;
    font-size: 131px;
    line-height: 182px;
    text-align: center;
    &__tower {
      height: 106.67px;
      width: 49.5px;
    }
    &__cog {
      height: 84px;
      width: 84px;
    }
    &__container {
      position: relative;
      margin-top: 15rem;
      margin-bottom: 22rem;
    }
    &__description {
      width: 890px;
      max-width: 85%;
      margin: auto;
      text-align: center;
      font-family: $font-family-1;
      font-weight: 400;
      font-size: 32px;
      line-height: 38px;
      margin-top: 12px;
    }
    &__img1 {
      position: absolute;
      left: 20%;
      top: 0;
    }
    &__img2 {
      position: absolute;
      left: 10%;
      top: 50%;
    }
    &__img3 {
      position: absolute;
      left: 15%;
      top: 150%;
    }
    &__img4 {
      position: absolute;
      left: 30%;
      top: 165%;
    }
    &__img5 {
      position: absolute;
      left: 40%;
      top: 155%;
      width: 141px;
      height: 141px;
    }
    &__img6 {
      position: absolute;
      left: 55%;
      top: 170%;
    }
    &__img7 {
      position: absolute;
      left: 65%;
      top: 172%;
    }
    &__img8 {
      position: absolute;
      left: 80%;
      top: 125%;
    }
    &__img9 {
      position: absolute;
      left: 70%;
      top: -15%;
    }
  }
  &__process {
    font-family: $font-family-2;
    font-weight: 700;
    font-size: 44px;
    line-height: 61px;
    margin-left: 12vw;
    margin-bottom: 25px;
  }
}

.design-card-container {
  max-width: 980px;
  width: 95%;
  margin: auto;
  margin-bottom: 210px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 120px;
}

.automation-section {
  margin-bottom: 0;
  padding-bottom: 210px;
}

.development-section__heading {
  &__img1 {
    position: absolute;
    width: 185px;
    height: 149px;
    top: 75%;
    left: 10%;
  }
  &__img2 {
    position: absolute;
    width: 229px;
    height: 209px;
    top: 130%;
    left: 45%;
  }
  &__img3 {
    position: absolute;
    width: 121px;
    height: 121px;
    top: 90%;
    left: 85%;
  }
  &__img4 {
    position: absolute;
    width: 200px;
    height: 200px;
    right: 0;
    top: -30vh;
  }
  &__img5 {
    position: absolute;
    width: 150px;
    height: 190px;
    left: 0;
    top: -25vh;
  }
}

.cloud-section__heading {
  &__img1 {
    position: absolute;
    width: 195px;
    height: 195px;
    top: 55%;
    left: 30px;
  }
  &__img2 {
    position: absolute;
    width: 240px;
    height: 248px;
    top: 120%;
    left: 45%;
  }
  &__img3 {
    position: absolute;
    width: 222px;
    height: 222px;
    top: 60%;
    right: 44px;
  }
  &__img4 {
    position: absolute;
    left: 0;
    width: 267px;
    height: 413px;
    top: -450px;
  }
  &__img5 {
    position: absolute;
    right: 0;
    width: 236px;
    height: 222px;
    top: -350px;
  }
}

.automation-section__heading {
  &__img1 {
    position: absolute;
    width: 170px;
    height: 170px;
    top: 55%;
    left: 30px;
  }
  &__img2 {
    position: absolute;
    width: 154px;
    height: 154px;
    top: 120%;
    left: 45%;
  }
  &__img3 {
    position: absolute;
    width: 154px;
    height: 154px;
    top: 60%;
    right: 44px;
  }
}

@media screen and (max-width: 960px) {
  .services-second__link {
    &__number {
      padding: 15px;
      font-size: 18px;
      line-height: 22px;
    }
    &__text {
      font-size: 32px;
      line-height: 44px;
    }
  }
  .design-section {
    &__heading {
      font-size: 32px;
      line-height: 44px;
      &__tower {
        height: 23.3px;
        width: 11px;
      }
      &__cog {
        height: 19px;
        width: 19px;
      }
      &__container {
        margin-top: 8rem;
        margin-bottom: 8rem;
      }
      &__description {
        font-size: 18px;
        line-height: 22px;
      }
      &__img1 {
        left: -40px;
      }
      &__img2 {
        display: none;
      }
      &__img3 {
        display: none;
      }
      &__img4 {
        display: none;
      }
      &__img5 {
        width: 77px;
        height: 77px;
        left: unset;
        right: 10px;
        top: -80px;
        transform: rotateY(180deg);
      }
      &__img6 {
        left: 80%;
        top: 110%;
      }
      &__img7 {
        display: none;
      }
      &__img8 {
        display: none;
      }
      &__img9 {
        display: none;
      }
    }
  }
  .design-card-container {
    margin-bottom: 120px;
    gap: 50px;
  }
  .development-card-container {
    gap: 50px;
  }
  .development-section__heading {
    &__img1 {
      width: 51.21px;
      height: 41.24px;
      top: 20%;
      left: 10px;
    }
    &__img2 {
      width: 63.39px;
      height: 57.85px;
      top: 110%;
      left: 35%;
    }
    &__img3 {
      width: 33.49px;
      height: 33.49px;
      top: 100%;
      left: unset;
      right: 20px;
    }
    &__img4 {
      width: 100px;
      height: 100px;
      top: -150px;
    }
    &__img5 {
      width: 75px;
      height: 95px;
      top: -120px;
    }
  }
  .cloud-section__heading {
    &__img1 {
      width: 46px;
      height: 46px;
      top: 15%;
      left: 11px;
    }
    &__img2 {
      width: 62px;
      height: 64px;
      top: 110%;
      left: 37px;
    }
    &__img3 {
      width: 42px;
      height: 42px;
      top: 100%;
      right: 27px;
    }
    &__img4 {
      width: 100px;
      height: 150px;
      top: -150px;
    }
    &__img5 {
      width: 118px;
      height: 111px;
      top: -120px;
    }
  }
  .automation-section__heading {
    &__img1 {
      width: 46px;
      height: 46px;
      top: 15%;
      left: 11px;
    }
    &__img2 {
      width: 62px;
      height: 64px;
      top: 110%;
      left: 37px;
    }
    &__img3 {
      width: 42px;
      height: 42px;
      top: 100%;
      right: 27px;
    }
  }
}
