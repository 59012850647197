@import "../../App.scss";

.dash-dark-mode {
  background-color: #fff;
  fill: #fff;
}

.light-dark-mode {
  background-color: #000;
}

.project__second-section {
  width: 85%;
  margin: auto;
  max-width: 1260px;
  padding: 70px 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 100px;
  }
  &__heading {
    font-weight: 700;
    font-size: 80px;
    font-family: $font-family-1;
    max-width: 578px;
    margin-bottom: 1rem;
    margin-top: 3rem;
  }
  &__dash {
    height: 4px;
    width: 163px;
  }
  &__para {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.06em;
    font-family: $font-family-1;
    margin: 72px 0;
    max-width: 413px;
  }
  &__explore {
    display: inline-flex;
    align-items: center;
    margin-top: 4rem;
    gap: 11px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    font-family: $font-family-1;
    cursor: pointer;
  }
  &__img-cont {
    max-width: 85vw;
  }
}

.bottom-mb {
  &__container {
    width: 95%;
    margin: auto;
    margin-top: 5rem;
    margin-bottom: 2rem;
    max-width: 1300px;
  }
}

.more-projects {
  &__container {
    width: 95%;
    margin: auto;
    margin-top: 10rem;
    max-width: 1300px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__div1 {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 12rem;
  }
  &__div2 {
    margin-top: 25rem;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 12rem;
  }
  &__title {
    margin-bottom: 2rem;
    font-family: $font-family-1;
    font-weight: 600;
    font-size: 32px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1280px) {
  .more-projects {
    &__container {
      margin-top: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    &__div1 {
      gap: 5rem;
    }
    &__div2 {
      margin-top: 5rem;
      gap: 5rem;
    }
    &__title {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 1160px) {
  .project__second-section {
    &__heading {
      font-size: 50px;
    }
  }
}

@media screen and (max-width: 960px) {
  .project__second-section {
    height: fit-content;
    padding: 40px;
    &__container {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 30px;
    }

    &__heading {
      font-size: 32px;
    }

    &__dash {
      height: 4px;
      width: 163px;
    }

    &__para {
      font-size: 16px;
      line-height: 18px;
      margin: 25px 0;
    }

    &__explore {
      gap: 5px;
      font-size: 16px;
      line-height: 14px;
      margin-top: 2rem;
    }
    &__img-cont {
      width: 100%;
    }
  }
}
