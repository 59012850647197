@import "../../App.scss";

.about__container__dark {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../../assets/images/photo-1530508777238-14544088c3ed.webp) center center;
}

.about__first-section {
  
  height: calc(100vh);
  width: 85%;
  margin: 89px auto;
  max-width: 1150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  &__heading {
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 700;
    font-size: 100px;
    line-height: 139%;
    text-align: center;
  }
  &__bulb {
    width: fit-content;
    margin: -80px auto;
    cursor: pointer;
  }
  &__explore {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
  }
}

.about__second-section {
  height: 100vh;
  width: 85%;
  margin: auto;
  max-width: 1150px;
  display: flex;
  align-items: center;
  justify-content: center;
  &__content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 40px;
    position: relative;
  }
  &__image {
    width: 60%;
    position: relative;
    &__one {
      max-width: 100%;
    }
    // &__1 {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   z-index: 2;
    //   width: 196px;
    //   height: 303px;
    //   object-fit: cover;
    // }
    // &__2 {
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   z-index: 1;
    //   width: 429px;
    //   height: 663px;
    //   object-fit: cover;
    // }
    // &__3 {
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    //   z-index: 2;
    //   width: 245px;
    //   height: 378px;
    //   object-fit: cover;
    // }
  }
  &__text-container__title {
    font-weight: 800;
    font-size: 44px;
    line-height: 61px;
    font-family: $font-family-1;
    margin-top: 65px;
    /* Gradient */
    width: fit-content;
    background: linear-gradient(90.13deg, #5edeaa 1.65%, #4de0ab 30.08%, #b1df5d 53.63%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }
  &__text-container__para {
    font-family: $font-family-1;
    margin: 24px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    max-width: 400px;
  }
}

.about__third-section {
  // height: 100vh;
  width: 85%;
  margin: auto;
  max-width: 1150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  &__heading {
    font-weight: 800;
    font-size: 44px;
    line-height: 61px;
    font-family: $font-family-2;
    margin: 100px;
    /* Gradient */
    width: fit-content;
    background: linear-gradient(90.13deg, #5edeaa 1.65%, #4de0ab 30.08%, #b1df5d 53.63%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }
}

.peopleCard-Container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

@media screen and (max-width: 960px) {
  .about__first-section {
    &__heading {
      font-size: 32px;
    }
    &__explore {
      font-size: 12px;
    }
  }
  .peopleCard-Container {
    gap: 20px;
  }
  .about__third-section {
  }
  .about__third-section__heading {
    font-size: 32px;
    line-height: 44px;
    margin: 0;
    margin-bottom: 20px;
  }
  .about__second-section {
    &__content {
      gap: 0;
    }
    &__text-container {
      margin-left: 24px;
    }

    &__image {
      align-self: flex-end;
      margin-right: 10px;
    }
    &__text-container {
      width: 40%;
      &__title {
        font-size: 32px;
        margin-top: 0px;
      }

      &__para {
        margin: 20px 0;
        font-size: 20px;
        max-width: unset;
      }
    }
  }
}

@media screen and (max-width: 730px) {
  .about__second-section {
    height: fit-content;
  }
  .about__second-section__content {
    flex-direction: column-reverse;
  }
  .about__second-section__image {
    margin: auto;
  }
  .about__second-section__text-container {
    text-align: center;
    width: 100%;
    margin: auto;
  }
  .about__second-section__text-container__title {
    margin: auto;
  }
}
